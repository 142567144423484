.recommendedBagWrapper {
  margin-top: 1em;
}

body[data-domain='camperlab'] .recommendedBagWrapper :global .gridContent {
  margin-bottom: 0.625rem;
}
body[data-domain='camperlab'] .recommendedBagWrapper :global .gridContent p:nth-child(2) {
  display: none;
}
body[data-domain='camperlab'] .recommendedBagWrapper :global .gridContent #product-card-lab-sizes {
  display: none;
}

.recommendedBagWrapper :global .slick-initialized {
  width: 100%;
  max-width: 18vw;
  overflow: hidden;
}

.recommendedBagWrapper :global .slick-list {
  margin-left: -0.5rem;
}
.recommendedBagWrapper :global .slick-slide > div {
  padding-left: 0.5rem;
}

body[data-domain='camperlab'] .recommendedBagWrapper :global .slick-slide > div {
  padding-left: 0;
}
body[data-domain='camperlab'] .recommendedBagWrapper :global .slick-slide img {
  background-color: theme('backgroundColor.clear');
}

.recommendedBagWrapper :global .slick-list {
  margin-right: 1rem;
}

body[data-domain='camperlab'] .recommendedBagWrapper :global .slick-list {
  margin-left: 0;
  margin-right: 0;
}

.title {
  border-bottom: 1px solid #dfdfdf;
  text-transform: uppercase;
  display: flex;
  font-size: var(--description-size);
  margin-bottom: 1em;
  padding-bottom: 5px;
  margin-right: 16px;
}

body[data-domain='camperlab'] .title {
  padding-bottom: 8px;
}

.recommendedBagWrapper :global .itemInfo,
.recommendedBagWrapper :global .customizeContainer {
  display: none;
}

.recommendedBagWrapper :global .gridContent {
  text-align: left;
  margin: 16px 0px;
  font-size: var(--description-size);
}

.recommendedBagWrapper :global .gridImages {
  padding-bottom: 0;
}

.recommendedBagWrapper :global .gridImages :global picture {
  position: initial;
  overflow: initial;
  height: 50%;
  width: 100%;
}

body[data-domain='camperlab'] .recommendedBagWrapper :global .image img {
  object-fit: contain;
  aspect-ratio: 2/3;
}

.recommendedBagWrapper :global .specialProductMessage {
  display: none;
}
.recommendedBagWrapper :global .slick-list {
  height: 280px;
}

body[data-domain='camperlab'] .recommendedBagWrapper :global .slick-list {
  height: auto;
}

body[data-domain='camperlab'] .recommendedBagWrapper :global .slick-slide {
  width: 112px;
}

.recommendedBagWrapper :global .slick-slide > div,
.recommendedBagWrapper :global .slick-slide > div > div,
.recommendedBagWrapper :global .slick-slide > div > div > div {
  height: 100%;
}
.recommendedBagWrapper :global .slick-slide > div > div > div {
  display: flex;
  flex-direction: column;
}
.recommendedBagWrapper :global .slick-slide > div > div > div > button {
  margin-top: auto;
  width: 100%;
}

.recommendedBagWrapper :global .slick-initialized {
  max-width: 100%;
}
.recommendedBagWrapper {
  max-width: 100%;
}

.recommendedBagWrapper :global .slick-initialized :global .slick-arrow {
  height: 24px;
  width: 24px;
  background-size: 14px;
  top: calc(50% - 24px);
}
